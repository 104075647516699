import React, { Component, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { projectName } from './theme';
import(`./scss_${process.env.REACT_APP_PROJECT_NAME}/style.scss`).then();
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
// Containers
const TheLayout = Loadable({
  loader: () => import('./containers/TheLayout'),
  loading,
});
// Pages
const Login = Loadable({
  loader: () => import('./views/pages/login/Login'),
  loading,
});
const Register = Loadable({
  loader: () => import('./views/pages/register/Register'),
  loading,
});
const Page404 = Loadable({
  loader: () => import('./views/pages/page404/Page404'),
  loading,
});
const Page500 = Loadable({
  loader: () => import('./views/pages/page500/Page500'),
  loading,
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loged: false,
    };
  }

  componentDidMount() {
    document.title = projectName;
  }

  render() {
    return (
      <HashRouter>
        {this.state.loading ? (
          <div>Cargando...</div>
        ) : (
          <Switch>
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
            {this.state.loged ? (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            ) : (
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Redirect to="/" />
              </Switch>
            )}
          </Switch>
        )}
      </HashRouter>
    );
  }
}
export default App;
