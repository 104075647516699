import React from 'react';

function PageLoader(props) {
  const { style, children, ...otherProps } = props;

  return (
    <div
      bg="white"
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '400px',
        ...style,
      }}
      {...otherProps}
    >
      <div>
        {/* {!props.children && <Spinner animation="border" variant="primary" />} */}
        {!props.children && 'Cargando..'}

        {props.children && <>{props.children}</>}
      </div>
    </div>
  );
}

export default PageLoader;
