import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from 'react';
import queryString from 'query-string';
import fakeAuth from 'fake-auth';
import PageLoader from './../components/PageLoader';
import feathers from '../feathers';

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = true;

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  // Handle response from authentication functions
  const handleAuth = async (user) => {
    // // Create the user in the database
    // // fake-auth doesn't indicate if they are new so we attempt to create user every time
    // await createUser(user._id, { email: user.email });

    // Update user in state

    let role = {};
    if (user.roleId) {
      role = await feathers.service('roles').get(user.roleId);
      user.role = role.name;
    }

    if(user.affiliates && user.groups){
      const groupsAffiliates = await feathers.service('affiliates').find({
        query: {
          groupId: { $in: user.groups },
          $select: [
            "_id"
          ]
        }
      });
  
      for (var i = 0; i < groupsAffiliates.total; i++)
        if (user.affiliates.indexOf(groupsAffiliates.data[i]._id) === -1)
          user.affiliates.push(groupsAffiliates.data[i]._id);
    }

    setUser(user);
    return user;
  };
  const signup = (email, password) => {
    // return fakeAuth
    //   .signup(email, password)
    //   .then((response) => handleAuth(response.user));
  };
  const changeRole=(role)=>{
    return feathers.authenticate({
      strategy:'local',
      role: role 
    })
  }
  const signin = (email, password) => {
    return feathers
      .authenticate({
        strategy: 'local',
        user: email,
        password: password,
      })
      .then((response) => handleAuth(response.user));
  };

  const signinWithProvider = (name) => {
    // return fakeAuth
    //   .signinWithProvider(name)
    //   .then((response) => handleAuth(response.user));
  };

  const signout = () => {
    return feathers.logout();
  };

  const sendPasswordResetEmail = (email) => {
    // return fakeAuth.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (password, code) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").
    // Get code from query string object
    // const resetCode = code || getFromQueryString('code');
    // return fakeAuth.confirmPasswordReset(password, resetCode);
  };

  const updateEmail = (email) => {
    // return fakeAuth.updateEmail(email).then((rawUser) => {
    //   setUser(rawUser);
    // });
  };

  const updatePassword = (password) => {
    return fakeAuth.updatePassword(password);
  };

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    // const { email, name, picture } = data;
    // // Update auth email
    // if (email) {
    //   await fakeAuth.updateEmail(email);
    // }
    // // Update auth profile fields
    // if (name || picture) {
    //   let fields = {};
    //   if (name) fields.name = name;
    //   if (picture) fields.picture = picture;
    //   await fakeAuth.updateProfile(fields);
    // }
    // // Persist all data to the database
    // await updateUser(user._id, data);
    // // Update user in state
    // const currentUser = await fakeAuth.getCurrentUser();
    // setUser(currentUser);
  };

  const reValidate = async () => {
    let token = await window.localStorage.getItem('feathers-jwt');

    feathers
      .authenticate({
        strategy: 'jwt',
        accessToken: token,
      })
      .then((response) => {
        // Logged in
        handleAuth(response.user);
      })
      .catch((e) => {
        // Show login page (potentially with `e.message`)
        setUser(false);
      });
  };

  useEffect(() => {
    reValidate();

    // Subscribe to user on mount
    // const unsubscribe = fakeAuth.onChange(async (response) => {
    //   if (response.user) {
    //     setUser(response.user);
    //   } else {
    //     setUser(false);
    //   }
    // });
    // // Unsubscribe on cleanup
    // return () => unsubscribe();
  }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
  };
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  // const userDbQuery = useUser(MERGE_DB_USER && user && user._id);
  // const userDbQuery = useUser(MERGE_DB_USER && user && user._id);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    let finalUser = {
      _id: user._id,
      user: user.user,
      role: user.role || 'Rol no asignado',
      affiliates: user.affiliates || [],
      groups: user.groups || [],
    };

    //Save in localStorage the affiliates
    localStorage.setItem("affiliates", JSON.stringify(user.affiliates));

    // Include an array of user's auth providers, such as ["password", "google", etc]
    // Components can read this to prompt user to re-auth with the correct provider
    // finalUser.providers = [user.provider];

    // If merging user data from database is enabled ...

    // if (MERGE_DB_USER) {
    //   switch (userDbQuery.status) {
    //     case 'idle':
    //       // Return null user until we have db data to merge
    //       return null;
    //     case 'loading':
    //       return null;
    //     case 'error':
    //       // Log query error to console
    //       console.error(userDbQuery.error);
    //       return null;
    //     case 'success':
    //       // If user data doesn't exist we assume this means user just signed up and the createUser
    //       // function just hasn't completed. We return null to indicate a loading state.
    //       if (userDbQuery.data === null) return null;

    //       // Merge user data from database into finalUser object
    //       Object.assign(finalUser, userDbQuery.data);

    //     // no default
    //   }
    // }

    return finalUser;
  }, [user]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        // history.replace('/login');
        window.location.hash = 'login';
        // alert('Salir');
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
