module.exports = {
  projectName: process.env.REACT_APP_PROJECT_NAME_TITLE,
  sideBar: {
    heightLogo: Number(process.env.REACT_APP_SIDEBAR_HEIGHT_LOGO),
    margin: Number(process.env.REACT_APP_SIDEBAR_MARGIN),
  },
  login: {
    heightLogo: Number(process.env.REACT_APP_LOGIN_HEIGHT_LOGO),
    backgroundColor: String(process.env.REACT_APP_LOGIN_BACKGROUND),
  },
};
